<script>
import TablePage from '../../../../components/table_page';
import Modal from '../../../../components/modal';
import Form from './form';

export default {
  name: 'terminal_audit_list',
  extends: TablePage,
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        code: '',
      },
    };
  },
  components: {
    Modal,
    Form,
  },
  methods: {

    afterGetList() {
      const tempData = this.tableData;
      const tempArr = [];
      tempData.forEach((item, index) => {
        tempArr.push({
          ...item.terminal,
          businessCode: item.businessCode,
          formNo: item.formNo,
          id: item.id,
          processCode: item.processCode,
          status: item.status,
          terminalCode: item.terminalCode,
        });
      });
      this.tableData = tempArr;
    },
    // 按钮点击事件
    modalClick({ val, row }) {
      this.formConfig = {};
      this.formName = 'Form';
      if (val.code === 'view') {
        this.formConfig = {
          ...val,
          code: val.code,
          row,
        };
        this.formConfig.code = 'view';
        this.modalConfig.title = '查看';
        this.openFull();
      }
    },
  },
  created() {
    this.getConfigList('terminal_audit_list');
  },
};
</script>
